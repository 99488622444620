<template>
  <!-- 巡检列表 -->
  <div class="setting_main_bg">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 待巡检点位列表 -->
      <el-tab-pane label="待巡检点位" name="first">
        <div class="flexAC">
          <el-input v-model="intaskcode"  placeholder="请输入巡检点名称/标签编号" class="contInput" style="width: 220px;"></el-input>
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>
        <div class="onplan_table">
          <el-table
            stripe
            :data="planList"
            style="width: 100%">
            <el-table-column
              label="巡检编号"
              prop="intaskcode"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="巡检点名称"
              prop="inpointname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="打卡类型"
              prop="inpointtype">
              <template slot-scope="scope">
                <span v-if="scope.row.inpointtype == 0">全部</span>
                <span v-if="scope.row.inpointtype == 1">扫码</span>
                <span v-if="scope.row.inpointtype == 2">NFC</span>
              </template>
            </el-table-column>
            <el-table-column
              label="标签编号"
              prop="inpointcode">
            </el-table-column>
            <el-table-column
              label="巡查周期"
              prop="cycle">
            </el-table-column>
            <el-table-column
              label="开始时间"
              prop="intaskstime">
            </el-table-column>
            <el-table-column
              label="结束时间"
              prop="intasketime">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </el-tab-pane>
      <!-- 巡检记录列表 -->
      <el-tab-pane label="巡检记录" name="second">
        <div class="flexAC">
          <el-input v-model="intaskcode"  placeholder="请输入巡检点名称/标签编号/巡检人" class="contInput" style="width: 330px;"></el-input>
          <el-select v-model="intaskstate" placeholder="全部状态" class="utF setSe adSe">
            <el-option
              v-for="(item,index) in taskstateOption"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button class="settingSearch" @click="siteSearch">搜索</el-button>
          <el-button class="settingReset" @click="siteleReset">重置</el-button>
          <div class="iconfont icondaochu settingExport" @click="handleExport"><span>导出</span></div>
        </div>
        <div class="onplan_table">
          <el-table
            stripe
            :data="siteList"
            style="width: 100%">
            <el-table-column
              label="巡检编号"
              prop="intaskcode"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="巡检点名称"
              prop="inpointname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="打卡类型"
              prop="inpointtype">
              <template slot-scope="scope">
                <span v-if="scope.row.inpointtype == 0">全部</span>
                <span v-if="scope.row.inpointtype == 1">扫码</span>
                <span v-if="scope.row.inpointtype == 2">NFC</span>
              </template>
            </el-table-column>
            <el-table-column
              label="标签编号"
              prop="inpointcode">
            </el-table-column>
            <el-table-column
              label="巡查周期"
              prop="cycle">
            </el-table-column>
            <el-table-column
              label="巡检时间"
              prop="intaskactime"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="巡检截止时间"
              prop="intasketime"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="巡检人"
              prop="username">
            </el-table-column>
            <el-table-column
              label="巡检状态"
              prop="intaskstate">
              <template slot-scope="scope">
                <span v-if="scope.row.intaskstate == 0">全部 </span>
                <span v-if="scope.row.intaskstate == 1">待巡检</span>
                <span v-if="scope.row.intaskstate == 2">已巡检</span>
                <span v-if="scope.row.intaskstate == 3">未巡检</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange1"
          :current-page="page1"
          :page-sizes="[10]"
          :page-size="size1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total1"
          class="settingPage">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 详情 -->
    <el-dialog title="巡检计划详情" :visible.sync="planlShow" width="800px" center class="gyDialog unit_addDialog">
      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">巡检点</p>
            <div style="margin-left: 10px;">
              <p class="udi_b" v-if="planInfo.intaskstate == 2">已巡检</p>
              <p class="udi_e" v-if="planInfo.intaskstate == 3">未巡检</p>
            </div>
          </div>
          <p class="udi_p">巡检点名称:{{ planInfo.inpointname }}</p>
          <div class="flexCB">
            <p class="udiIP">巡检人:{{ planInfo.username }}</p>
            <p class="udiIP">巡检时间:{{ planInfo.intaskactime }}</p>
          </div>
          <div class="flexCB">
            <p class="udiIP">巡检周期:{{ planInfo.cycle }}</p>
            <p class="udiIP">标签编号:{{ planInfo.inpointcode }}</p>
          </div>
          <p class="udiIP">巡检编号: {{ planInfo.intaskcode }}</p>
        </div>
      </div>
      <div class="flexSC">
        <div class="ud_left">
          <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
            <div class="udi_k"></div>
            <p class="udi_t">巡检要求</p>
          </div>
          <div class="ud_t">
            <p class="udiS" v-for="(item, index) in planInfo.inpointmark" :key="index"><span>{{ item.name }}</span></p>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="导出" :visible.sync="exportShow" width="450px" center class="gyDialog" :before-close="exportCancel">
      <p style="margin-bottom: 5px;">选择时间</p>
      <el-date-picker
        v-model="exportTime"
        value-format="yyyy-MM-dd"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        class="timePicker">
      </el-date-picker>
      <p style="color: #999;margin: 10px 0 20px;">提示：不选择时间则导出全部</p>
      <div class="flexCE">
        <div class="settingCancel" @click="exportCancel">取消</div>
        <div class="settingConfirm" @click="exportConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { onsiteList, onsiteListEx } from '@/http/api/onsite'
export default {
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      activeName: 'first',
      intaskcode: '',
      page: 1,
      size: 10,
      total: 0,
      planList: [], //待巡检列表
      page1: 1,
      size1: 10,
      total1: 0,
      siteList: [], //巡检记录列表
      intaskstate: '',
      taskstateOption: [
        { label: '全部状态', value: 0, },
        // { label: '待巡检', value: 1 },
        { label: '已巡检', value: 2 },
        { label: '未巡检', value: 3 }
      ],
      planlShow: false,
      planInfo: {},
      exportShow: false,
      exportTime: []
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    // 待巡检点位
    init () {
      var time = new Date()
      this.year = time.getFullYear()
      this.month = time.getMonth() + 1
      this.day = time.getDate()
      this.today = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-' + (this.day < 10 ? '0' + this.day : this.day)
      const params = {
        intaskcode: this.intaskcode,
        intaskstate: 1,
        intaskstime: this.today,
        companyid: this.companyid,
        page_index: this.page,
        page_size: this.size
      }
      onsiteList(params).then(res => {
        if (res.status === '1') {
          this.planList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.planList = []
          this.total = 0
        }
      })
    },
    // 详情
    handleDetail (row) {
      this.planlShow = true
      this.planInfo = row
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.intaskcode = ''
      this.init()
    },
    handleClick(tab) {
      if (tab.name == 'first') {
        this.init()
      } else {
        this.getInit()
      }
    },
    // 巡检记录
    getInit () {
      const params = {
        intaskcode: this.intaskcode,
        intaskstate: this.intaskstate,
        intaskstime: '',
        companyid: this.companyid,
        page_index: this.page1,
        page_size: this.size1
      }
      onsiteList(params).then(res => {
        if (res.status === '1') {
          this.siteList = res.data.data
          this.total1 = res.data.recordcount
        } else {
          this.siteList = []
          this.total1 = 0
        }
      })
    },
    // 详情
    siteDetail (row) {

    },
    PageChange1 (page) {
      this.page1 = page
      this.getInit()
    },
    siteSearch () {
      this.page1 = 1
      this.getInit()
    },
    siteleReset () {
      this.page1 = 1
      this.intaskcode = ''
      this.intaskstate = ''
      this.getInit()
    },
    /* 导出-展示 */
    handleExport () {
      this.exportShow = true
    },
    /* 导出-取消 */
    exportCancel () {
      this.exportShow = false
      this.exportTime = []
    },
    /* 导出-确定 */
    exportConfirm () {
      let st = ''
      let et = ''
      if (this.exportTime.length > 0) {
        st = this.exportTime[0]
        et = this.exportTime[1]
      }
      const params = {
        companyid: this.companyid,
        stime: st,
        etime: et
      }
      onsiteListEx(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          this.exportCancel()
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.ud_left{
  width: 800px;
}
.udi_p,.udiS,.udiIP,.de_lr{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.udiIP{
  width: 48%;
}
.udiS{
  width: 100%;
  margin-right: 1%;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-width: none;/* //火狐兼容 */
}
.ud_t::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.udi_b {
  width: 64px;
  height: 24px;
  background: #F6FFED;
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #B7EB8F;
  filter: blur(undefinedpx);
  color: #52C41A;
  text-align: center;
  line-height: 24px;
}
.udi_e {
  width: 64px;
  height: 24px;
  background: #FEEBEB;
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #FF4C4C;
  filter: blur(undefinedpx);
  color: #FF4C4C;
  text-align: center;
  line-height: 24px;
}
.timePicker{
  width: 100%;
}
</style>
